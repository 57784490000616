import React from "react";
import "../styles/pattern.css";

function Pattern({ icons, className }) {
  const generatePattern = () => {
    const patternElements = [];
    const numIcons = 100; // Número total de íconos en el patrón

    for (let i = 0; i < numIcons; i++) {
      const randomIcon = icons[Math.floor(Math.random() * icons.length)]; // Ícono aleatorio
      const randomSize = Math.random() * 3 + 1; // Tamaño entre 1rem y 4rem
      const randomX = Math.random() * 100; // Posición horizontal en %
      const randomY = Math.random() * 100; // Posición vertical en %

      patternElements.push(
        <img
          key={i}
          src={randomIcon}
          alt="pattern-icon"
          style={{
            position: "absolute",
            width: `${randomSize}rem`,
            left: `${randomX}%`,
            top: `${randomY}%`,
            opacity: Math.random() * 0.7 + 0.3, // Opacidad entre 0.3 y 1
          }}
        />
      );
    }

    return patternElements;
  };

  return <div className={`pattern-container ${className}`}>{generatePattern()}</div>;
}

export default Pattern;
