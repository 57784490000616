import React from 'react';
import Background  from "../assets/Fo0GwJiakAA6GC2.jpg";
import Examples from "../assets/41.png";
import BaileyGif from "../assets/add0b002-7065-4ccc-9841-e3316c02d0a8.gif";
import '../styles/home.css';

function Home(){

    return(
        <main className='home-gen'>
            <div className='aver'>
                <div className='home-back'>
                    <img className='home-img' src={Background} alt='cozy-background'/>
                </div>
                <div className='home-backtext'>
                    <h1>❝Ahora si ya a la verga tengo que dibujar todo esta mamada. Después hago RF ahora si ya jsjs❞</h1>
                    <img className='home-gif' src={BaileyGif} alt='bailey'/>
                </div>
            </div>

            
            <h1 className='home-title'>Some of my artwork</h1>

            <div className='home-3piece'>
                <img className='tpiece-img' src={Examples} alt='cozy-background'/>  
                <img className='tpiece-img' src={Examples} alt='cozy-background'/>
                <img className='tpiece-img' src={Examples} alt='cozy-background'/>
            </div>
        </main>
    );
}

export default Home;