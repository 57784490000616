import React from "react";
import "./styles/App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Pattern from "./components/Pattern";
import Icon1 from "./assets/heart.png";
import Icon2 from "./assets/love.png";
import Icon3 from "./assets/skull.png";
import Icon4 from "./assets/star.png";

function App() {
  const icons = [Icon1, Icon2, Icon3, Icon4];

  return (
    <div className="App">
      <div className="all-back">
        
      </div>
      <Pattern icons={icons} className="p-back" />
      <Navbar />
      <Home />
    </div>
  );
}

export default App;
