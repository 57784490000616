import React from 'react';
import '../styles/navbar.css';
import Icon from '../assets/Amber1.png'


function Navbar(){
    return(
        <main>
            <div className='navbar'>
                <img className='nav-icon' src={Icon} alt='page-icon'/>
                <div className='nav-links'>
                    <a href='#'>Works</a>
                    <a href='#'>Contact</a>
                </div>
            </div>
            
        </main>
    );
}

export default Navbar;